import { advertisementFavoriteApi } from 'modules/api/client';
import { AxiosResponse } from 'axios';

export const addToFavorite = async (
    advertisementId: string
): Promise<AxiosResponse<void>> => {
    try {
        const response =
            await advertisementFavoriteApi.createAdvertisementFavourite(
                advertisementId
            );

        if (response.status !== 201) {
            throw new Error(`Response status code was: ${response.status}`);
        }

        return response;
    } catch (error) {
        throw new Error(`Failed to add to favorite. Error: ${error.message}`);
    }
};
