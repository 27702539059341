'use client';

import { useCallback, useMemo } from 'react';
import { counties } from 'modules/analytics/ads/config/counties';
import { IFilterUrlDataLocation } from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/partials/location/IFilterUrlDataLocation';
import { IFilterUrlDataTransaction } from 'modules/api/clients/advertisement/filter/interfaces/partials/filterUrlData/partials/transaction/IFilterUrlDataTransaction';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { ICategory } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';
import { ICategories } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import { DtoCategoriesEnumToESefCategories } from 'components/page/advertisement/listing/constants/records/parameters/DtoCategoriesEnumToESefCategories';

export interface IMultipleCategories {
    [key: string]: string | string[];
}

export interface IUseAdsSecParams extends IMultipleCategories {
    countyShortName?: string;
    district?: string;
    city?: string;
    category?: string;
    subCategory?: string;
    type?: string;
    state?: string;
}

interface IReturn {
    adsSecParams?: IUseAdsSecParams;
}

export const useAdsSecParams = (
    initialCategories: ICategories,
    locationFromSef?: IFilterUrlDataLocation,
    transactionFromSef?: IFilterUrlDataTransaction,
    filterCategories?: (EMainCategory | ESubCategory)[]
): IReturn => {
    const mainCategoriesSefNames = useMemo(() => {
        if (!filterCategories || filterCategories.length === 0) {
            return [];
        }

        return filterCategories
            .filter((category) =>
                Object.values(EMainCategory).includes(category as EMainCategory)
            ) //@TODO toto nieje pekne riesenie, treba refactor
            .map((category) => DtoCategoriesEnumToESefCategories[category]);
    }, [filterCategories]);

    const subCategoriesSefNames = useMemo(() => {
        if (!filterCategories || filterCategories.length === 0) {
            return [];
        }

        return filterCategories
            .map((category) => DtoCategoriesEnumToESefCategories[category])
            .filter(Boolean);
    }, [filterCategories]);

    const allCategories = useMemo(() => {
        return [...initialCategories.main, ...initialCategories.sub];
    }, [initialCategories.main, initialCategories.sub]);

    const getParentSef = useCallback(
        (categorySef: string): string => {
            const categoryToSelect = allCategories.find(
                (category) => category.sefName === categorySef
            );

            let parentCategory: ICategory;

            if (categoryToSelect?.parentId && !categoryToSelect?.isMain) {
                parentCategory = allCategories.find(
                    (category) => category.id === categoryToSelect.parentId
                );
            }

            return parentCategory && parentCategory.id !== categoryToSelect.id
                ? parentCategory.sefName
                : null;
        },
        [allCategories]
    );

    const adsSecParams = useMemo(() => {
        const result: IUseAdsSecParams = {
            state: 'stav-vsetky',
        };

        if (
            locationFromSef &&
            locationFromSef?.parent?.parent &&
            locationFromSef?.name
        ) {
            result['countyShortName'] =
                counties[locationFromSef.parent.parent.name];
        }

        if (locationFromSef && locationFromSef?.parent?.sefName) {
            result['district'] = locationFromSef.parent.sefName;
        }

        if (locationFromSef && locationFromSef?.sefName) {
            result['city'] = locationFromSef?.sefName;
        }

        if (transactionFromSef && transactionFromSef?.sefName) {
            result['type'] = transactionFromSef.sefName;
        }

        if (subCategoriesSefNames.length) {
            subCategoriesSefNames.forEach((category, index) => {
                const parentCategorySef = getParentSef(category);
                if (parentCategorySef) {
                    result[`parentCategory${index}`] = parentCategorySef;
                }
            });
        }

        if (mainCategoriesSefNames.length) {
            mainCategoriesSefNames.forEach((category, index) => {
                result[`category${index}`] = category;
            });
        }

        if (subCategoriesSefNames.length) {
            subCategoriesSefNames.forEach((category, index) => {
                result[`subCategory${index}`] = category;
            });
        }

        return result;
    }, [
        getParentSef,
        locationFromSef,
        transactionFromSef,
        mainCategoriesSefNames,
        subCategoriesSefNames,
    ]);

    return {
        adsSecParams,
    };
};
