import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { useDetectAdBlock } from 'adblock-detect-react';
import useCookieBar from 'modules/state/app/hook/gdpr/useCookieBar';

interface IAdvertBox {
    children: ReactNode;
}

export const AdvertBox: FC<IAdvertBox> = ({ children }) => {
    const { isAllowed } = useCookieBar();
    const adBlockDetected = useDetectAdBlock();

    if (adBlockDetected || !isAllowed.googleDoubleClick) {
        return null;
    }

    return (
        <Box mb={4}>
            {children}
        </Box>
    );
};
