import { IPriceInfo } from 'modules/api/clients/advertisement/common/interfaces/price/IPriceInfo';
import { IPrice } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/price/IPrice';

export const mapIAdvertisementsListResultAdvertisementPriceToDsIAdvertisementIPrice =
    (apiPrice: IPriceInfo): IPrice => {
        const { price, unitPrice } = apiPrice;

        return {
            price,
            unitPrice,
        };
    };
