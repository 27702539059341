import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { mapQueryParamCategoryToOld } from 'modules/api/clients/advertisement/common/helpers/devProjectUrl/mapQueryParamCategoryToOld';

export const createUrlWithQueryParams = (
    category: (EMainCategory | ESubCategory)[] | EMainCategory | ESubCategory,
): string => {
    let categories: (EMainCategory | ESubCategory)[] = [];
    if (!Array.isArray(category)) {
        categories = [category];
    } else {
        categories = category;
    }

    const categoriesMapped = mapQueryParamCategoryToOld(categories);

    let queryParamsOldListing = '';
    const encodedParams = encodeURI('[categories][ids]');
    if (categoriesMapped && categoriesMapped.length) {
        queryParamsOldListing += `p${encodedParams}=${categoriesMapped.join('.')}`;
    }

    return queryParamsOldListing;
};