import { ECategoriesLabel } from 'modules/theme/components/listing/cards/devProjectCard/enums/category/ECategoriesLabel';
import { IAdvertisementsListResultDevProjectAdvertisementsInfo } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/devProject/partials/advertisementsInfo/IAdvertisementsListResultDevProjectAdvertisementsInfo';
import { IAdvertisementsInfo } from 'modules/theme/components/listing/cards/devProjectCard/interfaces/partials/advertisementsInfo/IAdvertisementsInfo';
import { ECategoryToDsCategory } from 'components/page/advertisement/listing/constants/records/result/devProjectCard/category/ECategoryToDsCategory';

export const mapIAdvertisementsListResultDevProjectAdvertisementsInfoToDsIDevProjectIAdvertisementsInfo =
    (
        advertisementsInfo: IAdvertisementsListResultDevProjectAdvertisementsInfo[]
    ): IAdvertisementsInfo => {
        let totalAdvertisementsCount = 0;
        const advertisementsCategories: ECategoriesLabel[] = [];
        const advertisementsCountMatchesTheFilter =
            advertisementsInfo.length > 0
                ? advertisementsInfo[0].countMatchesTheFilter
                : 0;

        for (const info of advertisementsInfo) {
            totalAdvertisementsCount += info.totalCount;

            advertisementsCategories.push(ECategoryToDsCategory[info.category]);
        }

        return {
            totalAdvertisementsCount,
            advertisementsCategories,
            advertisementsCountMatchesTheFilter,
        };
    };
