import * as React from 'react';
import {Stack} from '@mui/material';
import {TabBarItem} from 'modules/theme/components/tabbar/components/TabBarItem';
import {ITabBarItem} from 'modules/theme/components/tabbar/interfaces/ITabBarItem';

interface ITabBarProps {
    items: ITabBarItem[];
}

export const TabBar: React.FC<ITabBarProps> = ({items}) => {
    const activeCount = items.reduce((count, item) => {
        return count + (item.isActive === true ? 1 : 0);
    }, 0);

    if (activeCount > 1) {
        throw Error('You cannot use multiple isActive items');
    }

    return (
        <Stack
            direction="row"
            p={1}
            gap={3}
            justifyContent={'space-around'}
            borderTop={(theme) =>
                `1px solid ${theme.palette.dividerTransparent}`
            }
            bgcolor={(theme) => theme.palette.backgroundPrimary.main}
            boxShadow={'0px -2px 4px 0px rgba(0, 0, 0, 0.08)'}
        >
            {items.map((item, index) => (
                <TabBarItem item={item} key={`${item.id}-${index}`}/>
            ))}
        </Stack>
    );
};
