import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { IconsBox } from 'components/page/advertisement/listing/components/anotherOffer/partials/iconsBox/IconsBox';
import { Title } from 'components/page/advertisement/listing/components/anotherOffer/partials/title/Title';
import { Breadcrumbs } from 'components/page/advertisement/listing/components/anotherOffer/partials/breadcrumbs/Breadcrumbs';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
interface IProps {
    breadcrumbs: IBreadcrumb[]
}

export const AnotherOffer: FC<IProps> = ({ breadcrumbs }) => {
    return (
        <Stack justifyContent={'center'} alignItems={'center'}>
            <Box mb={1}>
                <IconsBox />
            </Box>
            <Box mb={3}>
                <Title />
            </Box>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Stack>
    );
};
