import { ESefCategories } from 'components/page/homePage/enums/categorySefs/ESefCategories';
import {
    EMainCategory
} from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import {
    ESubCategory
} from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

export const DtoCategoriesEnumToESefCategories: Record<(EMainCategory | ESubCategory), ESefCategories> = {
    [EMainCategory.APARTMENTS]: ESefCategories.APARTMENTS,
    [EMainCategory.HOUSES]: ESefCategories.HOUSES,
    [EMainCategory.SPACES]: ESefCategories.SPACES,
    [EMainCategory.OBJECTS]: ESefCategories.OBJECTS,
    [EMainCategory.REAL_ESTATES]: ESefCategories.REAL_ESTATES,
    [EMainCategory.COTTAGES_AND_CABINS]: ESefCategories.COTTAGES_AND_CABINS,
    [EMainCategory.LANDS]: ESefCategories.LANDS,
    [EMainCategory.SECONDARY_HOUSES]: ESefCategories.SECONDARY_HOUSES,
    [EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES]: ESefCategories.SECONDARY_RECREATIONAL_PROPERTIES,
    [EMainCategory.SECONDARY_LANDS]: ESefCategories.SECONDARY_LANDS,
    [EMainCategory.SECONDARY_SPACES_AND_OBJECTS]: ESefCategories.SECONDARY_SPACES_AND_OBJECTS,

    [ESubCategory.DOUBLE_STUDIO_APARTMENT]: ESefCategories.DOUBLE_STUDIO_APARTMENT,
    [ESubCategory.ONE_ROOM_APARTMENT]: ESefCategories.ONE_ROOM_APARTMENT,
    [ESubCategory.THREE_ROOM_APARTMENT]: ESefCategories.THREE_ROOM_APARTMENT,
    [ESubCategory.LOFT]: ESefCategories.LOFT,
    [ESubCategory.MAISONETTE]: ESefCategories.MAISONETTE,
    [ESubCategory.OTHER_LAND_TO_BUILDING]: ESefCategories.OTHER_LAND_TO_BUILDING,

    [ESubCategory.SECONDARY_STUDIO_APARTMENT]: ESefCategories.SECONDARY_STUDIO_APARTMENT,
    [ESubCategory.SECONDARY_THREE_ROOM_APARTMENT]: ESefCategories.SECONDARY_THREE_ROOM_APARTMENT,
    [ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES]: ESefCategories.SECONDARY_LAND_FOR_FAMILY_HOUSES,
    [ESubCategory.SECONDARY_RECREATIONAL_LAND]: ESefCategories.SECONDARY_RECREATIONAL_LAND,
    [ESubCategory.SECONDARY_COMMERCIAL_PLOTS]: ESefCategories.SECONDARY_COMMERCIAL_PLOTS,
    [ESubCategory.SECONDARY_GARDEN]: ESefCategories.SECONDARY_GARDEN,
    [ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]: ESefCategories.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS,
    [ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]: ESefCategories.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,

    [ESubCategory.SECONDARY_GARAGE_AND_PARKING]: ESefCategories.SECONDARY_GARAGE_AND_PARKING,
    [ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION]: ESefCategories.SECONDARY_OFFICES_AND_ADMINISTRATION,
    [ESubCategory.SECONDARY_THE_SHOP]: ESefCategories.SECONDARY_THE_SHOP,
    [ESubCategory.SECONDARY_A_RESTAURANT]: ESefCategories.SECONDARY_A_RESTAURANT,
    [ESubCategory.SECONDARY_WAREHOUSES]: ESefCategories.SECONDARY_WAREHOUSES,
    [ESubCategory.SECONDARY_PRODUCTION]: ESefCategories.SECONDARY_PRODUCTION,
    [ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]: ESefCategories.SECONDARY_ANOTHER_SPACE_AND_OBJECT,
    [ESubCategory.SECONDARY_HOTEL_GUESTHOUSE]: ESefCategories.SECONDARY_HOTEL_GUESTHOUSE,

    [ESubCategory.STUDIO_APARTMENT]: ESefCategories.STUDIO_APARTMENT,
    [ESubCategory.TWO_ROOM_APARTMENT]: ESefCategories.TWO_ROOM_APARTMENT,
    [ESubCategory.FOUR_ROOM_APARTMENT]: ESefCategories.FOUR_ROOM_APARTMENT,
    [ESubCategory.FIVE_PLUS_ROOM_APARTMENT]: ESefCategories.FIVE_PLUS_ROOM_APARTMENT,
    [ESubCategory.HOLIDAY_APARTMENT]: ESefCategories.HOLIDAY_APARTMENT,
    [ESubCategory.OTHER_TYPE_OF_APARTMENT]: ESefCategories.OTHER_TYPE_OF_APARTMENT,
    [ESubCategory.FAMILY_HOUSE]: ESefCategories.FAMILY_HOUSE,
    [ESubCategory.COUNTRY_HOUSE]: ESefCategories.COUNTRY_HOUSE,
    [ESubCategory.FARM_SETTLEMENT]: ESefCategories.FARM_SETTLEMENT,
    [ESubCategory.MOBILE_HOUSE]: ESefCategories.MOBILE_HOUSE,
    [ESubCategory.HOUSEBOAT]: ESefCategories.HOUSEBOAT,
    [ESubCategory.OTHER_OBJECT_FOR_HOUSING]: ESefCategories.OTHER_OBJECT_FOR_HOUSING,
    [ESubCategory.CABIN_AND_LOG_CABIN]: ESefCategories.CABIN_AND_LOG_CABIN,
    [ESubCategory.COTTAGE_AND_RECREATION_HOUSE]: ESefCategories.COTTAGE_AND_RECREATION_HOUSE,
    [ESubCategory.GARDEN_HUT]: ESefCategories.GARDEN_HUT,
    [ESubCategory.OTHER_RECREATIONAL_OBJECT]: ESefCategories.OTHER_RECREATIONAL_OBJECT,
    [ESubCategory.AGRICULTURAL_OBJECT]: ESefCategories.AGRICULTURAL_OBJECT,
    [ESubCategory.LAND_FOR_FAMILY_HOUSE]: ESefCategories.LAND_FOR_FAMILY_HOUSE,
    [ESubCategory.RECREATIONAL_LAND]: ESefCategories.RECREATIONAL_LAND,
    [ESubCategory.LAND_FOR_HOUSING_CONSTRUCTION]: ESefCategories.LAND_FOR_HOUSING_CONSTRUCTION,
    [ESubCategory.LAND_FOR_CIVIC_AMENITIES]: ESefCategories.LAND_FOR_CIVIC_AMENITIES,
    [ESubCategory.COMMERCIAL_ZONE]: ESefCategories.COMMERCIAL_ZONE,
    [ESubCategory.INDUSTRIAL_ZONE]: ESefCategories.INDUSTRIAL_ZONE,
    [ESubCategory.MIXED_ZONE]: ESefCategories.MIXED_ZONE,
    [ESubCategory.GARDEN]: ESefCategories.GARDEN,
    [ESubCategory.ORCHARD]: ESefCategories.ORCHARD,
    [ESubCategory.VINEYARD_AND_HOP_GARDEN]: ESefCategories.VINEYARD_AND_HOP_GARDEN,
    [ESubCategory.MEADOW_AND_PASTURE]: ESefCategories.MEADOW_AND_PASTURE,
    [ESubCategory.ARABLE_LAND]: ESefCategories.ARABLE_LAND,
    [ESubCategory.FOREST_LAND]: ESefCategories.FOREST_LAND,
    [ESubCategory.POND_AND_LAKE]: ESefCategories.POND_AND_LAKE,
    [ESubCategory.LAND_FOR_ADVERTISING]: ESefCategories.LAND_FOR_ADVERTISING,
    [ESubCategory.OTHER_TYPE_OF_LAND]: ESefCategories.OTHER_TYPE_OF_LAND,
    [ESubCategory.OFFICES]: ESefCategories.OFFICES,
    [ESubCategory.BUSINESS_SPACES]: ESefCategories.BUSINESS_SPACES,
    [ESubCategory.RESTAURANT_SPACES]: ESefCategories.RESTAURANT_SPACES,
    [ESubCategory.STORAGE_AREAS]: ESefCategories.STORAGE_AREAS,
    [ESubCategory.SPACE_FOR_PRODUCTION]: ESefCategories.SPACE_FOR_PRODUCTION,
    [ESubCategory.REPAIR_AREA]: ESefCategories.REPAIR_AREA,
    [ESubCategory.SPORTS_FACILITIES]: ESefCategories.SPORTS_FACILITIES,
    [ESubCategory.SPACE_FOR_ADVERTISEMENT]: ESefCategories.SPACE_FOR_ADVERTISEMENT,
    [ESubCategory.OTHER_TYPE_OF_SPACE]: ESefCategories.OTHER_TYPE_OF_SPACE,
    [ESubCategory.APARTMENT_HOUSE]: ESefCategories.APARTMENT_HOUSE,
    [ESubCategory.RENTAL_HOUSE]: ESefCategories.RENTAL_HOUSE,
    [ESubCategory.OFFICE_BUILDING]: ESefCategories.OFFICE_BUILDING,
    [ESubCategory.COMMERCIAL_OBJECT]: ESefCategories.COMMERCIAL_OBJECT,
    [ESubCategory.RESTAURANT]: ESefCategories.RESTAURANT,
    [ESubCategory.POLYFUNCTIONAL_BUILDING]: ESefCategories.POLYFUNCTIONAL_BUILDING,
    [ESubCategory.WAREHOUSE]: ESefCategories.WAREHOUSE,
    [ESubCategory.MANUFACTURING_FACILITY]: ESefCategories.MANUFACTURING_FACILITY,
    [ESubCategory.REPAIR_FACILITY]: ESefCategories.REPAIR_FACILITY,
    [ESubCategory.HOTEL_AND_PENSION]: ESefCategories.HOTEL_AND_PENSION,
    [ESubCategory.SPA]: ESefCategories.SPA,
    [ESubCategory.HISTORICAL_OBJECT]: ESefCategories.HISTORICAL_OBJECT,
    [ESubCategory.COMMERCIAL_FACILITY]: ESefCategories.COMMERCIAL_FACILITY,
    [ESubCategory.SPORT_OBJECT]: ESefCategories.SPORT_OBJECT,
    [ESubCategory.SMALL_POWER_STATION]: ESefCategories.SMALL_POWER_STATION,
    [ESubCategory.GAS_STATION]: ESefCategories.GAS_STATION,
    [ESubCategory.OBJECT_FOR_BREEDING_ANIMALS]: ESefCategories.OBJECT_FOR_BREEDING_ANIMALS,
    [ESubCategory.MOBILE_CELLS_AND_STANDS]: ESefCategories.MOBILE_CELLS_AND_STANDS,
    [ESubCategory.OTHER_TYPE_OF_OBJECT]: ESefCategories.OTHER_TYPE_OF_OBJECT,
    [ESubCategory.DETACHED_GARAGE]: ESefCategories.DETACHED_GARAGE,
    [ESubCategory.CONSOLIDATED_GROUND]: ESefCategories.CONSOLIDATED_GROUND,
    [ESubCategory.OTHER_AGRICULTURAL_LAND]: ESefCategories.OTHER_AGRICULTURAL_LAND,
};
