import { FC } from 'react';
import { BreadcrumbWithoutUrl } from 'components/page/advertisement/listing/components/listing/partials/listingHeader/partials/breadcrumbs/partials/partials/BreacrumbWithoutUrl';
import { BreadcrumbUrl } from 'components/page/advertisement/listing/components/listing/partials/listingHeader/partials/breadcrumbs/partials/partials/BreadcrumbUrl';
interface IProps {
    title: string;
    url?: string;
}

export const BreadcrumbsItem: FC<IProps> = ({ url, title }) => {
    return url ? (
        <BreadcrumbUrl url={url} title={title} />
    ) : (
        <BreadcrumbWithoutUrl title={title} />
    );
};
