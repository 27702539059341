import { FC, FunctionComponent, SVGProps } from 'react';
import { Stack, Theme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Title } from 'components/page/advertisement/listing/components/common/promoModule/common/title/Title';
import { CtaBtn } from 'components/page/advertisement/listing/components/common/promoModule/common/ctaBtn/CtaBtn';
import { Description } from 'components/page/advertisement/listing/components/common/promoModule/common/description/Description';

interface IPromoBox {
    onClick: () => void;
    title: string;
    btnText: string;
    description: string;
    promoIcon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const PromoBox: FC<IPromoBox> = ({
    onClick,
    title,
    btnText,
    promoIcon,
    description,
}) => {
    return (
        <Stack
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            border={'1px solid'}
            columnGap={4}
            borderRadius={(theme: Theme) => theme.spacing(2.5)}
            padding={(theme) => theme.spacing(4, 0, 4, 0)}
            borderColor={'rgba(182, 182, 182, 0.36)'}
        >
            <Svg icon component={promoIcon} width={88} height={56} />
            <Stack rowGap={1}>
                <Title text={title} />
                <Description description={description} />
            </Stack>
            <CtaBtn btnText={btnText} onClick={onClick} />
        </Stack>
    );
};
