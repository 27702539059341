import { getDevProjectDetailUrl } from 'modules/api/clients/advertisement/common/helpers/devProjectUrl/getDevProjectDetailUrl';
import { IDevProject } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/devProject/IDevProject';
import { IAdvertisementsListResultAdvertisementDevProject } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/advertisement/partials/devProject/IAdvertisementsListResultAdvertisementDevProject';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

export const mapIAdvertisementsListResultAdvertisementDevProjectToDsIDevProject =
    (
        devProject: IAdvertisementsListResultAdvertisementDevProject,
        filterCategoriesForDevProject: (EMainCategory | ESubCategory)[]
    ): IDevProject => {
        return {
            url: `${getDevProjectDetailUrl(
                devProject.externalId,
                devProject.slugName,
                filterCategoriesForDevProject
            )}`,
            name: devProject.name,
        };
    };
