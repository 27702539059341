import { FC } from 'react';
import { Box, Divider, Skeleton } from '@mui/material';

export const ExclusiveDevProjectsLoader: FC = () => {
    return (
        <>
            <Box mb={4}>
                <Divider sx={(theme) => ({
                    borderColor: theme.palette.dividerTransparent
                })}/>
            </Box>
            <Box mb={8}>
                <Box position='relative'>
                    <Box
                        mb={3}
                        display='flex'
                        flexDirection='column'
                        justifyContent='space-between'
                    >
                        <Box mb={1}>
                            <Skeleton width={250} height={28} sx={{
                                '&.MuiSkeleton-text': {
                                    transform: 'none'
                                }
                            }}/>
                        </Box>
                        <Box
                            gap={1}
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Box>
                                <Skeleton width={300} height={24} sx={{
                                    '&.MuiSkeleton-text': {
                                        transform: 'none'
                                    }
                                }}/>
                            </Box>
                            <Skeleton width={200} height={24} sx={{
                                '&.MuiSkeleton-text': {
                                    transform: 'none'
                                }
                            }}/>
                        </Box>
                    </Box>
                    <Skeleton sx={{
                        height: 290,
                        width: '100%',
                        '&.MuiSkeleton-text': {
                            transform: 'none'
                        }
                    }}/>
                </Box>
            </Box>
            <Box mb={4}>
                <Divider sx={(theme) => ({
                    borderColor: theme.palette.dividerTransparent
                })}/>
            </Box>
        </>
    );
};