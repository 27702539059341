'use client';

import { FC, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { Container, Box, useMediaQuery, useTheme } from '@mui/material';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { NotificationCenter } from 'components/notificationCenter/NotificationCenter';
import isHeaderHidden from 'modules/layout/isHeaderHidden';
import {
    AdvertisementsSearchFilter
} from 'components/advertisementsFilter/components/AdvertisementsSearchFilter';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { IAdvertisementsList } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementsList';
import { AdvertisementsListing } from 'components/page/advertisement/listing/components/listing/Listing';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import { AnotherOffer } from 'components/page/advertisement/listing/components/anotherOffer/AnotherOffer';
import { StickyBottomContent } from 'components/page/advertisement/listing/components/layout/StickyBottomContent';
import { SearchAgentModule } from 'components/page/advertisement/listing/components/searchAgentModule/SearchAgentModule';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import {
    IAdvertisementGtmListingItem
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';
import { StoreSetter } from 'components/page/advertisement/listing/components/storeSetter/StoreSetter';
import { IPageProps } from 'modules/page/app/interfaces/IPageProps';

interface IProps {
    pageProps: IPageProps;
    quickLinks: IBreadcrumb[];
    breadcrumbs: IBreadcrumb[];
    devProjectsInitial: IDevProject[];
    advertisements: IAdvertisementsList;
    filterDataFromUrl: FilterDataFromUrlType;
    initialSearchFilterData: IInitialSearchFilterData;
    pagination: IAdvertisementListingFilterPagination;
    advertisementGtmItems: IAdvertisementGtmListingItem[];
}

const Main: FC<IProps> = ({
    pageProps,
    breadcrumbs,
    quickLinks,
    pagination,
    advertisements,
    filterDataFromUrl,
    devProjectsInitial,
    advertisementGtmItems,
    initialSearchFilterData
}) => {
    const pathname = usePathname();
    const { breakpoints } = useTheme();
    const isMdDown = useMediaQuery(breakpoints.down('md'));

    const showNotifications = useMemo(() => {
        return isHeaderHidden(pathname) && isMdDown;
    }, [pathname, isMdDown]);

    return (
        <>
            <StoreSetter
                breadcrumbs={breadcrumbs}
                advertisementsList={advertisements}
                advertisementGtmItems={advertisementGtmItems}
            />

            <Container maxWidth={false} disableGutters={true}>
                <Box
                    top={{xs: 0}}
                    zIndex={{xs: 10}}
                    position={{xs: 'sticky', md: 'relative'}}
                    sx={(theme) => ({
                        borderBottom: { md: `1px solid ${theme.palette.dividerTransparent}` },
                        boxShadow: { xs: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)', md: 'none' },
                        backgroundColor: theme.palette.backgroundPrimary.main,
                    })}
                >
                    { showNotifications && <NotificationCenter /> }
                    <Container>
                        <Box
                            pb={2}
                            pt={2}
                            display='flex'
                            justifyContent='center'
                        >
                            <AdvertisementsSearchFilter
                                pagination={pagination}
                                filterDataFromUrl={filterDataFromUrl}
                                initialSearchFilterData={initialSearchFilterData}
                            />
                        </Box>
                    </Container>
                </Box>

                <Container>
                    <Box mb={4}>
                        <AdvertisementsListing
                            pagination={pagination}
                            breadcrumbs={breadcrumbs}
                            advertisements={advertisements}
                            searchParams={pageProps.searchParams}
                            filterDataFromUrl={filterDataFromUrl}
                            devProjectsInitial={devProjectsInitial}
                            advertisementGtmItems={advertisementGtmItems}
                            initialSearchFilterData={initialSearchFilterData}
                        />
                    </Box>

                    <Box mb={quickLinks.length ? 8 : 10}>
                        <SearchAgentModule
                            searchTitle={advertisements.title}
                            filterDataFromUrl={filterDataFromUrl}
                        />
                    </Box>
                    {quickLinks && quickLinks.length > 0 &&
                        <Box mb={10}>
                            <AnotherOffer breadcrumbs={quickLinks} />
                        </Box>
                    }
                </Container>

                <Box display={{xs: 'contents', md: 'none'}}>
                    <StickyBottomContent />
                </Box>
            </Container>
        </>
    );
};

export default Main;
