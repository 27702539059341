import { IDevProject } from 'modules/theme/components/listing/cards/devProjectCard/interfaces/IDevProject';
import { IPrice } from 'modules/theme/components/listing/cards/devProjectCard/interfaces/partials/price/IPrice';
import { ILabels } from 'modules/theme/components/listing/cards/devProjectCard/interfaces/partials/labels/ILabels';
import { IPackeges } from 'modules/theme/components/listing/cards/devProjectCard/interfaces/partials/packages/IPackeges';
import { IAdvertisementsInfo } from 'modules/theme/components/listing/cards/devProjectCard/interfaces/partials/advertisementsInfo/IAdvertisementsInfo';
import { IAdvertisementsListResultDevProject } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/devProject/IAdvertisementsListResultDevProject';
import { mapIAdvertisementsListResultDevProjectPriceToDsIDevProjectIPrice } from 'components/page/advertisement/listing/helpers/mapper/result/devProject/partials/price/mapIAdvertisementsListResultDevProjectPriceToDsIDevProjectIPrice';
import { mapIAdvertisementsListResultDevProjectFlagsToDsIDevProjectILabels } from 'components/page/advertisement/listing/helpers/mapper/result/devProject/partials/labels/mapIAdvertisementsListResultDevProjectFlagsToDsIDevProjectILabels';
import { mapIAdvertisementsListResultDevProjectFlagsToDsIDevProjectIPackages } from 'components/page/advertisement/listing/helpers/mapper/result/devProject/partials/packages/mapIAdvertisementsListResultDevProjectFlagsToDsIDevProjectIPackages';
import { mapIAdvertisementsListResultDevProjectAdvertisementsInfoToDsIDevProjectIAdvertisementsInfo } from 'components/page/advertisement/listing/helpers/mapper/result/devProject/partials/advertisementsInfo/mapIAdvertisementsListResultDevProjectAdvertisementsInfoToDsIDevProjectIAdvertisementsInfo';

export const mapIAdvertisementsListResultDevProjectToDsIDevProject = (
    devProject: IAdvertisementsListResultDevProject
): IDevProject => {
    const {
        id: apiId,
        title: apiTitle,
        flags: apiFlags,
        price: apiPrice,
        sefName: apiSefName,
        location: apiLocation,
        photoUrl: apiPhotoUrl,
        advertisementsInfo: apiAdvertisementsInfo,
    } = devProject;

    const location = apiLocation.name;

    const price: IPrice =
        mapIAdvertisementsListResultDevProjectPriceToDsIDevProjectIPrice(
            apiPrice
        );

    const labels: ILabels =
        mapIAdvertisementsListResultDevProjectFlagsToDsIDevProjectILabels(
            apiFlags
        );

    const packages: IPackeges =
        mapIAdvertisementsListResultDevProjectFlagsToDsIDevProjectIPackages(
            apiFlags
        );

    const advertisementsInfo: IAdvertisementsInfo =
        mapIAdvertisementsListResultDevProjectAdvertisementsInfoToDsIDevProjectIAdvertisementsInfo(
            apiAdvertisementsInfo
        );

    return {
        price,
        labels,
        location,
        packages,
        projectId: apiId,
        advertisementsInfo,
        imageUrl: apiPhotoUrl,
        projectName: apiTitle,
        projectSlugName: apiSefName,
    };
};
