import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface IDescription {
    description: string;
}

export const Description: FC<IDescription> = ({ description }) => {
    return (
        <Text variant={'body2'} mobileVariant={'body3'}>
            {description}
        </Text>
    );
};
