import { IAdvertisementsListResultAdvertisementFlags } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/advertisement/partials/flags/IAdvertisementsListResultAdvertisementFlags';
import { IAttachments } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/attachments/IAttachments';

export const mapIAdvertisementsListResultAdvertisementFlagsToDsIAdvertisementIAttachments =
    (flags: IAdvertisementsListResultAdvertisementFlags): IAttachments => {
        const { hasVideo, hasFloorPlan, hasInspections3d } = flags;

        return {
            hasVideo,
            hasFloorPlan,
            has3d: hasInspections3d,
        };
    };
