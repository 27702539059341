import { FC } from 'react';
import { Box } from '@mui/material';
import Link from 'components/link/Link';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { Title } from 'components/page/advertisement/listing/components/anotherOffer/partials/breadcrumbs/common/breadcrumb/partials/title/Title';
interface IProps {
    breadcrumb: IBreadcrumb;
}

export const Breadcrumb: FC<IProps> = ({ breadcrumb }) => {
    return (
        <Link
            noUnderline
            href={breadcrumb.url}
            sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            }}
        >
            <Box
                overflow='hidden'
                border='1px solid'
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                borderRadius={(theme) => theme.spacing(13)}
                padding={(theme) => theme.spacing(1.25, 2, 1.25, 2)}
                borderColor={(theme) => theme.palette.labelQuarternary.main}
                sx={(theme) => ({
                    '&:hover': {
                        backgroundColor: theme.palette.labelPrimary.main,
                        borderColor: theme.palette.labelPrimary.main,
                        '& .MuiTypography-root': {
                            color: theme.palette.backgroundPrimary.main,
                        },
                    },
                })}
            >
                <Title text={breadcrumb.title} />
            </Box>
        </Link>
    );
};
