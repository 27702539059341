import { FC } from 'react';
import { Box } from '@mui/material';
import { DevProject } from 'components/page/advertisement/listing/components/listing/partials/items/partials/item/devProject/DevProject';
import { Advertisement } from 'components/page/advertisement/listing/components/listing/partials/items/partials/item/advertisement/Advertisement';
import { ApiCategoriesType } from 'components/advertisementsFilter/modules/common/types/ApiCategoriesType';
import { IAdvertisementsListResult } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/IAdvertisementsListResult';
import {
    IAdvertisementGtmListingItem
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';

interface IItems {
    page: number;
    limit: number;
    items: IAdvertisementsListResult[];
    categoriesFromSearchFilter: ApiCategoriesType;
    advertisementGtmItems: IAdvertisementGtmListingItem[];
}

export const Items: FC<IItems> = ({
    page,
    items,
    limit,
    advertisementGtmItems,
    categoriesFromSearchFilter,
}) => {

    return (
        <>
            {items.map((item, index) => {
                const gtmItem =
                    advertisementGtmItems.find((gtmItem) => gtmItem.id === item.advertisement?.id);

                return <Box key={index}>
                    {!!item.advertisement && (
                        <Advertisement
                            page={page}
                            index={index}
                            limit={limit}
                            isFirstAdvertisement
                            advertisement={item.advertisement}
                            advertisementGtmItem={gtmItem}
                            filterCategoriesForDevProject={
                                categoriesFromSearchFilter
                            }
                        />
                    )}

                    {!!item.devProject && (
                        <DevProject
                            page={page}
                            index={index}
                            limit={limit}
                            devProject={item.devProject}
                            advertisementGtmItem={gtmItem}
                        />
                    )}
                </Box>;
            })}
        </>
    );
};
