import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface ITtitle {
    text: string;
}

export const Title: FC<ITtitle> = ({ text }) => {
    return (
        <Text variant={'h4'} mobileVariant={'h5'} semibold>
            {text}
        </Text>
    );
};
