import { FC, useState } from 'react';
import { Box, Popper, ClickAwayListener } from '@mui/material';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';
import { IOption } from 'modules/theme/components/fields/select/interfaces/IOption';
import SortIcon from 'modules/theme/components/icons/basic/Sort.svg';
import { Text } from 'modules/theme/components/text/Text';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IOrderBy {
    isMobile?: boolean;
    isDisabled: boolean;
    selectedValue: EOrder;
    onChange: (order: EOrder) => void;
}

export const OrderBy: FC<IOrderBy> = ({
    onChange,
    isMobile,
    isDisabled,
    selectedValue,
}) => {
    const options: IOption[] = [
        { value: EOrder.DEFAULT, name: 'Odporúčané' },
        { value: EOrder.NEWEST, name: 'Najnovšie' },
        { value: EOrder.PRICE_ASC, name: 'Najlacnejšie' },
        { value: EOrder.PRICE_DESC, name: 'Najdrahšie' },
    ];
    const value = options.find((option) => option.value === selectedValue).name;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
        if (!event) return setAnchorEl(null);

        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return <>
        <Box
            gap={1}
            display='flex'
            alignItems='center'
            onClick={handleClick}
            sx={(theme) => ({
                cursor: 'pointer',
                pointerEvents: isDisabled && 'none',
                '& svg': {
                    color: isDisabled
                        ? theme.palette.labelTertiary.main
                        : theme.palette.labelPrimary.main
                }
            })}
        >
            <Svg component={SortIcon} icon/>

            {!isMobile &&
                <Text
                    semibold
                    variant='label1'
                    color={(palette) => isDisabled
                        ? palette.labelTertiary
                        : palette.labelPrimary}
                >
                    {value}
                </Text>
            }
        </Box>

        <Popper
            modifiers={[
                {
                    name: 'offset',
                    options: { offset: [
                        10,
                        10
                    ]},
                },
            ]}
            sx={{
                zIndex: 2,
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement='bottom'
        >
            <ClickAwayListener onClickAway={() => handleClick()} touchEvent={false}>
                <Box
                    p={2}
                    display='flex'
                    flexDirection='column'
                    bgcolor={(theme) => theme.palette.backgroundPrimary.main}
                    sx={{
                        borderRadius: '20px',
                        boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.16)'
                    }}
                >
                    {options.map((option) => (
                        <Box
                            key={option.value}
                            py={1}
                            sx={{
                                cursor: 'pointer',
                                '& .MuiTypography-root': {
                                    '&:hover': {
                                        fontWeight: 600
                                    }
                                }
                            }}
                            onClick={() => {
                                onChange(option.value as EOrder);
                                handleClick();
                            }}
                        >
                            <Text
                                variant='label1'
                                semibold={selectedValue === option.value}
                            >
                                {option.name}
                            </Text>
                        </Box>
                    ))}
                </Box>
            </ClickAwayListener>
        </Popper>
    </>;
};