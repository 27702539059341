import { IAgency } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/agency/IAgency';
import { IAdvertisementsListResultAdvertisementAdvertiser } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/common/advertiser/IAdvertisementsListResultAdvertisementAdvertiser';

export const mapIAdvertisementsListResultAdvertisementAdvertiserToDsIAdvertisementIAgency =
    (apiParent: IAdvertisementsListResultAdvertisementAdvertiser): IAgency => {
        const { name } = apiParent;

        return {
            nameOfAdvertiser: name || null,
        };
    };
