import { IPriceInfo } from 'modules/api/clients/advertisement/common/interfaces/price/IPriceInfo';
import { IPrice } from 'modules/theme/components/listing/cards/devProjectCard/interfaces/partials/price/IPrice';

export const mapIAdvertisementsListResultDevProjectPriceToDsIDevProjectIPrice =
    (apiPrice: IPriceInfo): IPrice => {
        const { price, unitPrice } = apiPrice;

        return {
            price,
            unitPrice,
        };
    };
