
import { IAdvertisementsListResultAdvertisementAdvertiser } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/common/advertiser/IAdvertisementsListResultAdvertisementAdvertiser';
import { ISeller } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/seller/ISeller';

export const mapIAdvertisementsListResultAdvertisementAdvertiserToDsIAdvertisementISeller =
    (
        apiAdvertiser: IAdvertisementsListResultAdvertisementAdvertiser
    ): ISeller => {
        const { isPro, parent, photoUrl } = apiAdvertiser;

        return {
            isSellerPro: isPro,
            sellerPhotoUrl: photoUrl,
            isAgencyProfi: !!parent?.isPro,
        };
    };
