import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { newCategoryIdToOldNskId } from 'modules/api/clients/advertisement/common/helpers/devProjectUrl/newCategoryIdToOldNskId';

export const mapQueryParamCategoryToOld = (
    categoriesFromFilter?: (EMainCategory | ESubCategory)[],
): string[] => {
    const oldNskCategories: string[] = [];

    if (categoriesFromFilter && categoriesFromFilter.length) {
        categoriesFromFilter.forEach((categoryFromFilter) => {
            const categoriesRemaped: number[] = newCategoryIdToOldNskId[categoryFromFilter];

            if (categoriesRemaped && categoriesRemaped.length) {
                oldNskCategories.push(...categoriesRemaped.map((category) => category.toString()));
            }
        });
    }

    return oldNskCategories;
};