import { IAdvertisementsListResultDevProjectFlags } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/devProject/partials/flags/IAdvertisementsListResultDevProjectFlags';
import { ILabels } from 'modules/theme/components/listing/cards/devProjectCard/interfaces/partials/labels/ILabels';

export const mapIAdvertisementsListResultDevProjectFlagsToDsIDevProjectILabels =
    (apiFlags: IAdvertisementsListResultDevProjectFlags): ILabels => {
        const { isProfi } = apiFlags;

        return {
            isProfi,
        };
    };
