import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

export const Title: FC = () => {
    return (
        <Text variant="h4" noWrap semibold>
            Ďalšia ponuka pre vás
        </Text>
    );
};
