import { FC } from 'react';
import { Box } from '@mui/material';
import { MobileTabBar } from 'components/page/advertisement/listing/components/common/mobileTabBar/MobileTabBar';

export const StickyBottomContent: FC = () => {
    return (
        <Box position={'sticky'} bottom={0} zIndex={2} width={'100%'}>
            <MobileTabBar />
        </Box>
    );
};
