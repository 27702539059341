import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { DEV_PROJECT_DETAIL } from 'modules/route/routes';
import { createUrlWithQueryParams } from 'modules/api/clients/advertisement/common/helpers/devProjectUrl/createUrlWithQueryParams';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

export const getDevProjectDetailUrl = (
    id: string,
    slugName: string,
    filterCategories:
        | (EMainCategory | ESubCategory)[]
        | EMainCategory
        | ESubCategory = [],
    fullText?: string
): string => {
    const categoriesOldUrl = createUrlWithQueryParams(
        filterCategories,
    );

    let query = categoriesOldUrl.length ? `?${categoriesOldUrl}` : '';

    if (fullText) {
        query += `?p[keyword]=${fullText.replace(' ', '-')}`;
    }

    return `${AppConfigService.getNehnutelnostiUrl()}/${DEV_PROJECT_DETAIL(
        id,
        slugName
    )}${query}`;
};
