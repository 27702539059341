import * as React from 'react';
import Link from 'components/link/Link';
import {Stack, Box,} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import {Text} from 'modules/theme/components/text/Text';
import {ITabBarItem} from 'modules/theme/components/tabbar/interfaces/ITabBarItem';

interface ITabBarItemProps {
    item: ITabBarItem
}

export const TabBarItem: React.FC<ITabBarItemProps> = ({item}) => {

    return (
        <Link noUnderline href={item.href}>
            <Stack
                direction="column"
                pt={.5}
                alignItems={'center'}
                color={theme => item.isActive ? theme.palette.primary.main : theme.palette.labelSecondary.main}
                sx={{
                    '&:hover': {
                        color: 'primary.main',
                    },
                }}
            >
                <Box lineHeight={0}>
                    <Svg icon component={item.icon} width={28}/>
                </Box>
                <Text
                    color={
                        (palette) => item.isActive ? palette.labelPrimary : palette.labelSecondary
                    }
                    variant="breadcrumb"
                    semibold
                    noWrap
                >
                    {item.label}
                </Text>
            </Stack>
        </Link>
    );
};
