'use client';

import { FC, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { AdsPag } from 'components/ads/enums/AdsPag';
import { AdsHead } from 'components/ads/components/adsHead/AdsHead';
import useCookieBar from 'modules/state/app/hook/gdpr/useCookieBar';
import { listingAdvertisementIds } from 'modules/state/page/listing/state';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { useAdsSecParams } from 'components/page/advertisement/listing/hooks/ads/useAdsSecParams';
import {
    IAdvertisementsList
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementsList';
import {
    IInitialSearchFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';

interface IAdvertisementListingHead {
    adsHeadScript: string;
    advertisements: IAdvertisementsList;
    filterDataFromUrl: FilterDataFromUrlType;
    pagination: IAdvertisementListingFilterPagination;
    initialSearchFilterData: IInitialSearchFilterData;
}

export const AdvertisementListingHead: FC<IAdvertisementListingHead> = ({
    pagination,
    adsHeadScript,
    advertisements,
    filterDataFromUrl,
    initialSearchFilterData
}) => {
    const [, setAdvertisementIds] = useRecoilState(listingAdvertisementIds);

    const { isAllowed } = useCookieBar();

    useEffect(() => {
        const advertisementIds: string[] = advertisements.results
            .map((advertisement) => {
                if (advertisement.advertisement?.id) {
                    return advertisement.advertisement?.id;
                }

                return '';
            })
            .filter(value => !!value);

        setAdvertisementIds(advertisementIds);
    }, [advertisements.results, setAdvertisementIds]);

    const {
        adsSecParams,
    } = useAdsSecParams(
        initialSearchFilterData.categories,
        filterDataFromUrl.locations[0],
        filterDataFromUrl.transaction,
        filterDataFromUrl.categories.map((category) => category.name)
    );


    useEffect(() => {
        if (!isAllowed?.googleDoubleClick) {
            return;
        }

        window['adsPag'] = [AdsPag.LISTING];
        window['adsSec'] = Object.values(adsSecParams)?.filter(i => i);

        if (typeof window.updateAdsTargeting === 'function') {
            window.updateAdsTargeting();
        }
    }, [filterDataFromUrl.locations, filterDataFromUrl.transaction, filterDataFromUrl.categories, pagination, adsSecParams, isAllowed?.googleDoubleClick]);

    return <AdsHead headScript={adsHeadScript} adsPag={AdsPag.LISTING} adsSec={adsSecParams} />;
};