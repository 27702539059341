import { EMainCategory as EApiCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ECategoriesLabel as EDsCategory } from 'modules/theme/components/listing/cards/devProjectCard/enums/category/ECategoriesLabel';

export const ECategoryToDsCategory: Record<EApiCategory, EDsCategory> = {
    [EApiCategory.REAL_ESTATES]: EDsCategory.APARTMENTS,
    [EApiCategory.APARTMENTS]: EDsCategory.APARTMENTS,
    [EApiCategory.HOUSES]: EDsCategory.HOUSES,
    [EApiCategory.SECONDARY_HOUSES]: EDsCategory.HOUSES,
    [EApiCategory.COTTAGES_AND_CABINS]: EDsCategory.COTTAGES_AND_CABINS,
    [EApiCategory.LANDS]: EDsCategory.LANDS,
    [EApiCategory.SECONDARY_LANDS]: EDsCategory.LANDS,
    [EApiCategory.SPACES]: EDsCategory.SPACES,
    [EApiCategory.OBJECTS]: EDsCategory.OBJECTS,
    [EApiCategory.SECONDARY_RECREATIONAL_PROPERTIES]: EDsCategory.SECONDARY_RECREATIONAL_PROPERTIES,
    [EApiCategory.SECONDARY_SPACES_AND_OBJECTS]: EDsCategory.SECONDARY_SPACES_AND_OBJECTS,
};
