import { FC } from 'react';
import { Button } from 'modules/theme/components/button/Button';
interface ICtaBtn {
    onClick: () => void;
    btnText: string;
}

export const CtaBtn: FC<ICtaBtn> = ({ onClick, btnText }) => {
    return <Button text={btnText} tertiary onClick={onClick} />;
};
