import React, { FC } from 'react';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { PromoModule } from 'components/page/advertisement/listing/components/common/promoModule/PromoModule';
import SaveSearchIcon from 'components/page/advertisement/listing/assets/icons/SaveSearchIcon.svg';
import useSearchAgent from 'components/searchAgent/hooks/useSearchAgent';

interface ISearchAgentModule {
    searchTitle?: string;
    filterDataFromUrl: FilterDataFromUrlType;
}

export const SearchAgentModule: FC<ISearchAgentModule> = ({ filterDataFromUrl, searchTitle }) => {
    const { modal, show } = useSearchAgent(filterDataFromUrl, searchTitle);

    return (
        <>
            <PromoModule
                btnText={'Uložiť hľadanie'}
                icon={SaveSearchIcon}
                title={'Uložte si toto hľadanie'}
                description={
                    'Upozorníme vás na všetky nové podobné nehnuteľnosti'
                }
                onClick={show}
            />
            {modal}
        </>
    );
};
