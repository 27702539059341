import { EGtmEvent } from 'modules/gtmEvents/enums/EGtmEvent';
import { EItemListId } from 'modules/gtmEvents/enums/EItemListId';
import {
    getAdvertisementListItem,
    getAdvertisementListItems,
} from 'modules/gtmEvents/helpers/events/advertisement/listing/partials/advertisementListDataHelper';
import { IAdvertisementViewItemListEvent } from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementViewItemListEvent';
import { EPromotionId } from 'modules/gtmEvents/enums/EPromotionId';
import { IAdvertisementViewPromotionEvent } from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementViewPromotionEvent';
import { ECreativeSlot } from 'modules/gtmEvents/enums/ECreativeSlot';
import { IAdvertisementSelectItemEvent } from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementSelectItemEvent';
import { IAddToWishListEvent } from 'modules/gtmEvents/interfaces/wishList/IAddToWishListEvent';
import { ECurrency } from 'modules/gtmEvents/enums/ECurrency';
import { getOrderIndex } from 'modules/gtmEvents/helpers/gtmHelper';
import {
    IAdvertisementGtmListingItem
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';
import { ECreativeName } from 'modules/gtmEvents/enums/ECreativeName';
import {
    IAdvertisementSelectPromotionEvent
} from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementSelectPromotionEvent';

export const getAdvertisementViewItemListEvent = (
    itemListName: string,
    advertisements: IAdvertisementGtmListingItem[],
    currentPage: number,
    limit: number
): IAdvertisementViewItemListEvent => {
    return {
        event: EGtmEvent.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_id: EItemListId.CLASSIFIED_SEARCH_RESULTS,
            item_list_name: itemListName as EItemListId,
            items: getAdvertisementListItems(
                advertisements,
                currentPage,
                limit,
                'LT',
            ),
        },
    };
};

export const getTopAdvertisementsViewItemListEvent = (
    advertisements: IAdvertisementGtmListingItem[],
    currentPage: number,
    limit: number
): IAdvertisementViewPromotionEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            promotion_id: EPromotionId.TOP_OF_LIST,
            promotion_name: EPromotionId.TOP_OF_LIST,
            creative_slot: ECreativeSlot.LIST,
            items: getAdvertisementListItems(
                advertisements,
                currentPage,
                limit,
                'LT',
            ),
        },
    };
};

export const getTopAdvertisementViewItemListEvent = (
    advertisement: IAdvertisementGtmListingItem,
    index: number,
    currentPage: number,
    limit: number
): IAdvertisementViewPromotionEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            promotion_id: EPromotionId.TOP_OF_LIST,
            promotion_name: EPromotionId.TOP_OF_LIST,
            creative_slot: ECreativeSlot.LIST,
            items: [
                getAdvertisementListItem(
                    advertisement,
                    getOrderIndex(index, currentPage, limit),
                    'LT',
                )
            ],
        },
    };
};

export const getPremiumAdvertisementsViewItemListEvent = (
    advertisements: IAdvertisementGtmListingItem[],
    currentPage: number,
    limit: number
): IAdvertisementViewPromotionEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            promotion_id: EPromotionId.PREMIUM_OF_LIST,
            promotion_name: EPromotionId.PREMIUM_OF_LIST,
            creative_slot: ECreativeSlot.LIST,
            items: getAdvertisementListItems(
                advertisements,
                currentPage,
                limit,
                'LT',
            ),
        },
    };
};

export const getPremiumAdvertisementViewItemListEvent = (
    advertisement: IAdvertisementGtmListingItem,
    index: number,
    currentPage: number,
    limit: number
): IAdvertisementViewPromotionEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            promotion_id: EPromotionId.PREMIUM_OF_LIST,
            promotion_name: EPromotionId.PREMIUM_OF_LIST,
            creative_slot: ECreativeSlot.LIST,
            items: [
                getAdvertisementListItem(
                    advertisement,
                    getOrderIndex(index, currentPage, limit),
                    'LT',
                )
            ],
        },
    };
};

export const getAdvertisementSelectListItemEvent = (
    advertisement: IAdvertisementGtmListingItem,
    currentIndex: number,
    currentPage: number,
    limit: number
): IAdvertisementSelectItemEvent => {
    return {
        event: EGtmEvent.SELECT_ITEM,
        ecommerce: {
            item_list_id: EItemListId.CLASSIFIED_SEARCH_RESULTS,
            item_list_name: advertisement.gaItemListName as EItemListId,
            items: [
                getAdvertisementListItem(
                    advertisement,
                    getOrderIndex(currentIndex, currentPage, limit),
                    'LT',
                ),
            ],
        },
    };
};

export const getSelectPromotionPremiumEvent = (
    advertisement: IAdvertisementGtmListingItem,
    currentIndex: number,
    currentPage: number,
    limit: number
): IAdvertisementSelectPromotionEvent => {
    return {
        event: EGtmEvent.SELECT_PROMOTION,
        ecommerce: {
            creative_name: ECreativeName.CLASSIFIEDS_SEARCH_RESULTS,
            creative_slot: ECreativeSlot.LIST,
            promotion_id: EPromotionId.PREMIUM_OF_LIST,
            promotion_name: EPromotionId.FEATURED_LISTINGS,
            items: [
                getAdvertisementListItem(
                    advertisement,
                    getOrderIndex(currentIndex, currentPage, limit),
                    'LT',
                )
            ]
        }
    };
};

export const getSelectPromotionTopEvent = (
    advertisement: IAdvertisementGtmListingItem,
    currentIndex: number,
    currentPage: number,
    limit: number
): IAdvertisementSelectPromotionEvent => {
    return {
        event: EGtmEvent.SELECT_PROMOTION,
        ecommerce: {
            creative_name: ECreativeName.CLASSIFIEDS_SEARCH_RESULTS,
            creative_slot: ECreativeSlot.LIST,
            promotion_id: EPromotionId.TOP_OF_LIST,
            promotion_name: EPromotionId.FEATURED_LISTINGS,
            items: [
                getAdvertisementListItem(
                    advertisement,
                    getOrderIndex(currentIndex, currentPage, limit),
                    'LT',
                )
            ]
        }
    };
};

export const getAdvertisementAddToWishListListItemEvent = (
    advertisement: IAdvertisementGtmListingItem,
    currentIndex: number,
    currentPage: number,
    limit: number
): IAddToWishListEvent => {
    return {
        event: EGtmEvent.ADD_TO_WISHLIST,
        ecommerce: {
            currency: ECurrency.EUR,
            value: advertisement.price.priceValue,
            items: [
                getAdvertisementListItem(
                    advertisement,
                    getOrderIndex(currentIndex, currentPage, limit),
                    'LT'
                ),
            ],
        },
    };
};
