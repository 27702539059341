import {FC, FunctionComponent, SVGProps} from 'react';
import {
    PromoBox as DesktopValueBox
} from 'components/page/advertisement/listing/components/common/promoModule/partials/desktop/PromoBox';
import {
    PromoBox as MobileValueBox
} from 'components/page/advertisement/listing/components/common/promoModule/partials/mobile/PromoBox';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

interface IPromoModule {
    title: string;
    btnText: string;
    description: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    onClick: () => void
}

export const PromoModule: FC<IPromoModule> = ({
    onClick,
    icon,
    title,
    btnText,
    description,
}) => {
    return <>
        <Mobile>
            <MobileValueBox
                onClick={onClick}
                title={title}
                promoIcon={icon}
                btnText={btnText}
                description={description}
            />
        </Mobile>
        <Desktop>
            <DesktopValueBox
                onClick={onClick}
                title={title}
                promoIcon={icon}
                btnText={btnText}
                description={description}
            />
        </Desktop>
    </>;
};
