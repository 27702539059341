import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

export const newCategoryIdToOldNskId: Partial<Record<EMainCategory | ESubCategory, number[]>> = {
    [EMainCategory.APARTMENTS]: [1], // Byty
    [EMainCategory.SECONDARY_HOUSES]: [2], // Domy
    [EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES]: [3], // Rekreacne nehnutelnosti
    [EMainCategory.SECONDARY_LANDS]: [6], // Pozemky
    [EMainCategory.SECONDARY_SPACES_AND_OBJECTS]: [4, 5], // Priestory a objekty

    [ESubCategory.SECONDARY_STUDIO_APARTMENT]: [10000], // Garsonka, Dvojgarsonka
    [ESubCategory.ONE_ROOM_APARTMENT]: [10001], // 1-izbovy
    [ESubCategory.TWO_ROOM_APARTMENT]: [10002], // 2-izbovy
    [ESubCategory.SECONDARY_THREE_ROOM_APARTMENT]: [10003], // 3-izbovy
    [ESubCategory.FOUR_ROOM_APARTMENT]: [10004], // 4-izbovy
    [ESubCategory.FIVE_PLUS_ROOM_APARTMENT]: [10005], // 5+ byt
    [ESubCategory.HOLIDAY_APARTMENT]: [10007], // Apartman
    [ESubCategory.LOFT]: [10010], // Loft
    [ESubCategory.MAISONETTE]: [10006], // Mezonet

    [ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES]: [60001, 60008], // Pozemok pre rodinne domy
    [ESubCategory.SECONDARY_RECREATIONAL_LAND]: [60002], // Rekreacny pozemok
    [ESubCategory.SECONDARY_COMMERCIAL_PLOTS]: [60003, 60004, 60006, 60007, 60005, 60017], // Komercne pozemky
    [ESubCategory.SECONDARY_GARDEN]: [60009], // Zahrada
    [ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]: [60011, 60013], // Sady, vinice, chmelnice
    [ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]: [60010, 60012, 60014, 60015, 60016], // Luky, pasienky

    [ESubCategory.SECONDARY_GARAGE_AND_PARKING]: [40018, 40019], // Garaze
    [ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION]: [40003, 50001], // Kancelárie a administratíva
    [ESubCategory.SECONDARY_THE_SHOP]: [40005, 50002], // Obchod
    [ESubCategory.SECONDARY_A_RESTAURANT]: [40006, 50003], // Restauracie
    [ESubCategory.SECONDARY_WAREHOUSES]: [40012, 50007], // Sklady
    [ESubCategory.SECONDARY_PRODUCTION]: [40011, 50006], // Vyroba
    [ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]: [
        50004,
        50008,
        50010,
        50005,
        50009,
        40004,
        40021,
        40001,
        40013,
        40014,
        40023,
        40002,
        40009,
        40015,
        40008,
        40016,
        40020,
        40024,
        40010,
        40017,
        40022,
    ], // Iný priestor a objekt
    [ESubCategory.SECONDARY_HOTEL_GUESTHOUSE]: [40007], // Hotel, penzión
};