import { IAdvertisementsListResultDevProjectFlags } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/devProject/partials/flags/IAdvertisementsListResultDevProjectFlags';
import { IPackeges } from 'modules/theme/components/listing/cards/devProjectCard/interfaces/partials/packages/IPackeges';

export const mapIAdvertisementsListResultDevProjectFlagsToDsIDevProjectIPackages =
    (apiFlags: IAdvertisementsListResultDevProjectFlags): IPackeges => {
        const { isTop, isPremium } = apiFlags;

        return {
            isTop,
            isPremium,
        };
    };
