import { ESubCategory as EApiSubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { ESubcategory as EDsSubCategory } from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';

export const ESubCategoryToDsSubCategory: Record<
    EApiSubCategory,
    EDsSubCategory
> = {
    [EApiSubCategory.STUDIO_APARTMENT]: EDsSubCategory.STUDIO_APARTMENT,
    [EApiSubCategory.DOUBLE_STUDIO_APARTMENT]:
        EDsSubCategory.DOUBLE_STUDIO_APARTMENT,
    [EApiSubCategory.ONE_ROOM_APARTMENT]: EDsSubCategory.ONE_ROOM_APARTMENT,
    [EApiSubCategory.TWO_ROOM_APARTMENT]: EDsSubCategory.TWO_ROOM_APARTMENT,
    [EApiSubCategory.THREE_ROOM_APARTMENT]: EDsSubCategory.THREE_ROOM_APARTMENT,
    [EApiSubCategory.FOUR_ROOM_APARTMENT]: EDsSubCategory.FOUR_ROOM_APARTMENT,
    [EApiSubCategory.FIVE_PLUS_ROOM_APARTMENT]:
        EDsSubCategory.FIVE_PLUS_ROOM_APARTMENT,
    [EApiSubCategory.HOLIDAY_APARTMENT]: EDsSubCategory.HOLIDAY_APARTMENT,
    [EApiSubCategory.LOFT]: EDsSubCategory.LOFT,
    [EApiSubCategory.MAISONETTE]: EDsSubCategory.MAISONETTE,
    [EApiSubCategory.OTHER_TYPE_OF_APARTMENT]:
        EDsSubCategory.OTHER_TYPE_OF_APARTMENT,
    [EApiSubCategory.FAMILY_HOUSE]: EDsSubCategory.FAMILY_HOUSE,
    [EApiSubCategory.COUNTRY_HOUSE]: EDsSubCategory.COUNTRY_HOUSE,
    [EApiSubCategory.FARM_SETTLEMENT]: EDsSubCategory.FARM_SETTLEMENT,
    [EApiSubCategory.MOBILE_HOUSE]: EDsSubCategory.MOBILE_HOUSE,
    [EApiSubCategory.HOUSEBOAT]: EDsSubCategory.HOUSEBOAT,
    [EApiSubCategory.OTHER_OBJECT_FOR_HOUSING]:
        EDsSubCategory.OTHER_OBJECT_FOR_HOUSING,
    [EApiSubCategory.CABIN_AND_LOG_CABIN]: EDsSubCategory.CABIN_AND_LOG_CABIN,
    [EApiSubCategory.COTTAGE_AND_RECREATION_HOUSE]:
        EDsSubCategory.COTTAGE_AND_RECREATION_HOUSE,
    [EApiSubCategory.GARDEN_HUT]: EDsSubCategory.GARDEN_HUT,
    [EApiSubCategory.OTHER_RECREATIONAL_OBJECT]:
        EDsSubCategory.OTHER_RECREATIONAL_OBJECT,
    [EApiSubCategory.LAND_FOR_FAMILY_HOUSE]:
        EDsSubCategory.LAND_FOR_FAMILY_HOUSE,
    [EApiSubCategory.RECREATIONAL_LAND]: EDsSubCategory.RECREATIONAL_LAND,
    [EApiSubCategory.LAND_FOR_HOUSING_CONSTRUCTION]:
        EDsSubCategory.LAND_FOR_HOUSING_CONSTRUCTION,
    [EApiSubCategory.LAND_FOR_CIVIC_AMENITIES]:
        EDsSubCategory.LAND_FOR_CIVIC_AMENITIES,
    [EApiSubCategory.COMMERCIAL_ZONE]: EDsSubCategory.COMMERCIAL_ZONE,
    [EApiSubCategory.INDUSTRIAL_ZONE]: EDsSubCategory.INDUSTRIAL_ZONE,
    [EApiSubCategory.MIXED_ZONE]: EDsSubCategory.MIXED_ZONE,
    [EApiSubCategory.GARDEN]: EDsSubCategory.GARDEN,
    [EApiSubCategory.ORCHARD]: EDsSubCategory.ORCHARD,
    [EApiSubCategory.VINEYARD_AND_HOP_GARDEN]:
        EDsSubCategory.VINEYARD_AND_HOP_GARDEN,
    [EApiSubCategory.MEADOW_AND_PASTURE]: EDsSubCategory.MEADOW_AND_PASTURE,
    [EApiSubCategory.ARABLE_LAND]: EDsSubCategory.ARABLE_LAND,
    [EApiSubCategory.FOREST_LAND]: EDsSubCategory.FOREST_LAND,
    [EApiSubCategory.POND_AND_LAKE]: EDsSubCategory.POND_AND_LAKE,
    [EApiSubCategory.LAND_FOR_ADVERTISING]: EDsSubCategory.LAND_FOR_ADVERTISING,
    [EApiSubCategory.OTHER_TYPE_OF_LAND]: EDsSubCategory.OTHER_TYPE_OF_LAND,
    [EApiSubCategory.OFFICES]: EDsSubCategory.OFFICES,
    [EApiSubCategory.BUSINESS_SPACES]: EDsSubCategory.BUSINESS_SPACES,
    [EApiSubCategory.RESTAURANT_SPACES]: EDsSubCategory.RESTAURANT_SPACES,
    [EApiSubCategory.STORAGE_AREAS]: EDsSubCategory.STORAGE_AREAS,
    [EApiSubCategory.SPACE_FOR_PRODUCTION]: EDsSubCategory.SPACE_FOR_PRODUCTION,
    [EApiSubCategory.REPAIR_AREA]: EDsSubCategory.REPAIR_AREA,
    [EApiSubCategory.SPORTS_FACILITIES]: EDsSubCategory.SPORTS_FACILITIES,
    [EApiSubCategory.SPACE_FOR_ADVERTISEMENT]:
        EDsSubCategory.SPACE_FOR_ADVERTISEMENT,
    [EApiSubCategory.OTHER_TYPE_OF_SPACE]: EDsSubCategory.OTHER_TYPE_OF_SPACE,
    [EApiSubCategory.APARTMENT_HOUSE]: EDsSubCategory.APARTMENT_HOUSE,
    [EApiSubCategory.RENTAL_HOUSE]: EDsSubCategory.RENTAL_HOUSE,
    [EApiSubCategory.OFFICE_BUILDING]: EDsSubCategory.OFFICE_BUILDING,
    [EApiSubCategory.COMMERCIAL_OBJECT]: EDsSubCategory.COMMERCIAL_OBJECT,
    [EApiSubCategory.RESTAURANT]: EDsSubCategory.RESTAURANT,
    [EApiSubCategory.POLYFUNCTIONAL_BUILDING]:
        EDsSubCategory.POLYFUNCTIONAL_BUILDING,
    [EApiSubCategory.WAREHOUSE]: EDsSubCategory.WAREHOUSE,
    [EApiSubCategory.MANUFACTURING_FACILITY]:
        EDsSubCategory.MANUFACTURING_FACILITY,
    [EApiSubCategory.REPAIR_FACILITY]: EDsSubCategory.REPAIR_FACILITY,
    [EApiSubCategory.OBJECT_FOR_BREEDING_ANIMALS]:
        EDsSubCategory.OBJECT_FOR_BREEDING_ANIMALS,
    [EApiSubCategory.HOTEL_AND_PENSION]: EDsSubCategory.HOTEL_AND_PENSION,
    [EApiSubCategory.SPA]: EDsSubCategory.SPA,
    [EApiSubCategory.HISTORICAL_OBJECT]: EDsSubCategory.HISTORICAL_OBJECT,
    [EApiSubCategory.COMMERCIAL_FACILITY]: EDsSubCategory.COMMERCIAL_FACILITY,
    [EApiSubCategory.SPORT_OBJECT]: EDsSubCategory.SPORT_OBJECT,
    [EApiSubCategory.SMALL_POWER_STATION]: EDsSubCategory.SMALL_POWER_STATION,
    [EApiSubCategory.GAS_STATION]: EDsSubCategory.GAS_STATION,
    [EApiSubCategory.MOBILE_CELLS_AND_STANDS]:
        EDsSubCategory.MOBILE_CELLS_AND_STANDS,
    [EApiSubCategory.OTHER_TYPE_OF_OBJECT]: EDsSubCategory.OTHER_TYPE_OF_OBJECT,
    [EApiSubCategory.DETACHED_GARAGE]: EDsSubCategory.DETACHED_GARAGE,
    [EApiSubCategory.CONSOLIDATED_GROUND]: EDsSubCategory.CONSOLIDATED_GROUND,
    [EApiSubCategory.AGRICULTURAL_OBJECT]:
        EDsSubCategory.OTHER_AGRICULTURAL_OBJECT,
    [EApiSubCategory.OTHER_LAND_TO_BUILDING]:
        EDsSubCategory.OTHER_LAND_TO_BUILDING,
    [EApiSubCategory.OTHER_AGRICULTURAL_LAND]:
        EDsSubCategory.OTHER_AGRICULTURAL_LAND,

    [EApiSubCategory.SECONDARY_STUDIO_APARTMENT]:
        EDsSubCategory.STUDIO_APARTMENT,
    [EApiSubCategory.SECONDARY_THREE_ROOM_APARTMENT]:
        EDsSubCategory.THREE_ROOM_APARTMENT,
    [EApiSubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES]:
        EDsSubCategory.LAND_FOR_FAMILY_HOUSE,
    [EApiSubCategory.SECONDARY_RECREATIONAL_LAND]:
        EDsSubCategory.RECREATIONAL_LAND,
    [EApiSubCategory.SECONDARY_COMMERCIAL_PLOTS]:
        EDsSubCategory.COMMERCIAL_PLOTS,
    [EApiSubCategory.SECONDARY_GARDEN]: EDsSubCategory.GARDEN,
    [EApiSubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]:
        EDsSubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS,
    [EApiSubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]:
        EDsSubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,
    [EApiSubCategory.SECONDARY_GARAGE_AND_PARKING]:
        EDsSubCategory.SECONDARY_GARAGE_AND_PARKING,
    [EApiSubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION]:
        EDsSubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION,
    [EApiSubCategory.SECONDARY_THE_SHOP]: EDsSubCategory.SECONDARY_THE_SHOP,
    [EApiSubCategory.SECONDARY_A_RESTAURANT]:
        EDsSubCategory.SECONDARY_A_RESTAURANT,
    [EApiSubCategory.SECONDARY_WAREHOUSES]: EDsSubCategory.SECONDARY_WAREHOUSES,
    [EApiSubCategory.SECONDARY_PRODUCTION]: EDsSubCategory.SECONDARY_PRODUCTION,
    [EApiSubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]:
        EDsSubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT,
    [EApiSubCategory.SECONDARY_HOTEL_GUESTHOUSE]:
        EDsSubCategory.SECONDARY_HOTEL_GUESTHOUSE,
};
