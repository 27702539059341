import { IAdvertisementsListResultAdvertisementPhoto } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/advertisement/partials/photo/IAdvertisementsListResultAdvertisementPhoto';
import { IPhotos } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/photos/IPhotos';

export const mapIAdvertisementsListResultAdvertisementPhotoToDsIAdvertisementIPhotos =
    (
        apiPhotos: IAdvertisementsListResultAdvertisementPhoto[],
        priorityLoad?: boolean,
    ): IPhotos => {
        const previewPhotoUrl =
            apiPhotos.length > 0 ? apiPhotos[0].url : undefined;
        const thumbsPhotosUrl =
            apiPhotos.length > 0 ? apiPhotos.slice(1).map((p) => p.url) : [];

        return {
            previewPhotoUrl,
            thumbsPhotosUrl,
            priorityLoad,
        };
    };
