export enum AdSlot {
    AD_MOBILE_TOP = 'nehnutelnosti-mobile-mobile-top',
    AD_DESKTOP_SQUARE = 'nehnutelnosti-desktop-square',
    AD_DESKTOP_SCREEN_TOP = 'nehnutelnosti-desktop-screen-top',
    AD_DESKTOP_LIST_1 = 'nehnutelnosti-desktop-banner-list-1',
    AD_DESKTOP_LIST_2 = 'nehnutelnosti-desktop-banner-list-2',
    AD_DESKTOP_LIST_3 = 'nehnutelnosti-desktop-banner-list-3',
    AD_DESKTOP_LIST_4 = 'nehnutelnosti-desktop-banner-list-4',
    AD_MOBILE_LIST_1 = 'nehnutelnosti-mobile-banner-list-1',
    AD_MOBILE_LIST_2 = 'nehnutelnosti-mobile-banner-list-2',
    AD_MOBILE_LIST_3 = 'nehnutelnosti-mobile-banner-list-3',
    AD_MOBILE_LIST_4 = 'nehnutelnosti-mobile-banner-list-4',
}
