'use client';

import {
    ADS_DETAIL,
    ADS_HP,
    ADS_SEARCH,
} from 'components/ads/constants/adSlots';
import { usePathname } from 'next/navigation';
import { FC, useEffect, useMemo } from 'react';
import { AdSlot } from 'components/ads/enums/AdSlot';
import { Box, Theme, useMediaQuery } from '@mui/material';
import useCookieBar from 'modules/state/app/hook/gdpr/useCookieBar';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        googletag: any;
    }
}

type AdProps = {
    slotMobile?: AdSlot;
    slotDesktop?: AdSlot;
};

export const Ad: FC<AdProps> = ({ slotMobile, slotDesktop }) => {
    const pathname = usePathname();
    const { isAllowed } = useCookieBar();

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const adSlot = useMemo(
        () => (isMobile ? slotMobile : slotDesktop),
        [isMobile, slotDesktop, slotMobile]
    );

    const adsSlots = useMemo(
        () =>
            pathname
                ? [
                    ...(pathname === '/' ? ADS_HP : []),
                    ...(pathname.startsWith('/detail/') || pathname.startsWith('/caroffice') ? ADS_DETAIL : []),
                    ...(pathname.startsWith('/vysledky') ? ADS_SEARCH : []),
                ]
                : null,
        [pathname]
    );

    useEffect(() => {
        if (!adSlot || !adsSlots || !isAllowed?.googleDoubleClick) {
            return;
        }

        if (adsSlots.includes(adSlot)) {
            let { googletag } = window;
            googletag = googletag || {};
            googletag?.cmd?.push(function () {
                googletag.display(`div-gpt-ad-${adSlot}`);
            });
        }
    }, [adSlot, adsSlots, isAllowed?.googleDoubleClick]);

    return adSlot && isAllowed?.googleDoubleClick ? (
        <Box
            width="100%"
            textAlign="center"
            boxSizing="border-box"
            id={`div-gpt-ad-${adSlot}`}
        />
    ) : null;
};
