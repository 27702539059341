import { FC } from 'react';
import { Box } from '@mui/material';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import { SaveSearchBtn } from 'modules/theme/components/listing/saveSearchBtn/SaveSearchBtn';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { useSearchStore } from 'components/advertisementsFilter/features/search/store/useSearchStore';
import { OrderBy } from 'components/page/advertisement/listing/components/listing/partials/listingHeader/partials/orderBy/OrderBy';
import { useRedirectByFilter } from 'components/advertisementsFilter/features/search/hooks/useRedirectByFilter';

interface IOrderBySection {
    searchTitle: string;
    advertisementsCount: number;
    filterDataFromUrl: FilterDataFromUrlType;
}

export const OrderBySection: FC<IOrderBySection> = ({
    searchTitle,
    filterDataFromUrl,
    advertisementsCount,
}) => {
    const currentOrder = useSearchStore((store) => store.currentOrder);
    const { onOrderChangeAndRedirect } = useRedirectByFilter();

    return <>
        <Mobile>
            <Box display='flex' alignItems='center' gap={3}>
                <OrderBy
                    isMobile
                    selectedValue={currentOrder}
                    isDisabled={advertisementsCount <= 1}
                    onChange={(order) => {
                        onOrderChangeAndRedirect(order);
                    }}
                />
                <SaveSearchBtn
                    filterDataFromUrl={filterDataFromUrl}
                    isMobile searchTitle={searchTitle}
                />
            </Box>
        </Mobile>
        <Desktop>
            <Box display='flex' alignItems='center' gap={3}>
                <OrderBy
                    selectedValue={currentOrder}
                    isDisabled={advertisementsCount <= 1}
                    onChange={(order) => {
                        onOrderChangeAndRedirect(order);
                    }}/>

                <SaveSearchBtn
                    isMobile={false}
                    filterDataFromUrl={filterDataFromUrl}
                    searchTitle={searchTitle}
                />
            </Box>
        </Desktop>
    </>;
};