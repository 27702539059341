import { IAddress } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/address/IAddress';
import { IAdvertisementListResultLocation } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/common/location/name/IAdvertisementListResultLocation';

export const mapIAdvertisementsListResultAdvertisementLocationToDsIAdvertisementIAddress =
    (location: IAdvertisementListResultLocation): IAddress => {
        const { name } = location;

        return {
            location: name,
        };
    };
