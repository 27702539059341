import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import { ILivemonitorEvent } from 'modules/analytics/IAnalytics';
import { ELMEvent } from 'components/page/realEstate/livemonitorEvents/enums/ELMEvent';
import { ESectionId } from 'components/page/realEstate/livemonitorEvents/enums/ESectionId';

const getAdvertisementItemShowEvent = (parameters: string[], projectName?: string): ILivemonitorEvent => {
    const livemonitorEvent = {
        p: [...parameters]
    };
    if (projectName){
        livemonitorEvent['o'] = { pn: projectName };
    }
    return livemonitorEvent;
};

export const setViewItemListAdvertisementsRealEstateDetailEvents = (livemonitorSendEvent: (event: ILivemonitorEvent) => void, advertisements: IAdvertisement[], projectName?: string): void => {
    advertisements.map((advertisement: IAdvertisement) => {
        livemonitorSendEvent( getAdvertisementItemShowEvent(
            ['trackEvent', ELMEvent.SHOW_ON_NSK, advertisement.id, ESectionId.LISTING_DETAIL_RK],
            projectName
        ));
    });
};

export const setViewItemListAdvertisementsRealEstateListingEvents = (livemonitorSendEvent: (event: ILivemonitorEvent) => void, advertisementIds: string[], projectName?: string): void => {
    advertisementIds.map((id: string) => {
        livemonitorSendEvent( getAdvertisementItemShowEvent(
            ['trackEvent', ELMEvent.SHOW_ON_NSK, id, ESectionId.LISTING],
            projectName
        ));
    });
};
