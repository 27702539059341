import { IAdvertisementsListResultAdvertisementSearchResultsLink } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/advertisement/partials/searchResults/IAdvertisementsListResultAdvertisementSearchResultsLink';
import { IListingUrl } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/listingUrl/IListingUrl';
import { ADVERTISEMENT_SEARCH } from 'modules/route/routes';

export const mapIAdvertisementsListResultAdvertisementASearchResultLinkToDsIListingUrl =
    (
        searchResultsLink: IAdvertisementsListResultAdvertisementSearchResultsLink
    ): IListingUrl => {
        const {
            location: { name: locationName, sefName: locationSefName },
            category: { name: categoryName, sefName: categorySefName },
            transaction: { name: transactionName, sefName: transactionSefName },
        } = searchResultsLink.filterData;

        return {
            url: `${ADVERTISEMENT_SEARCH(
                `${categorySefName}/${locationSefName}/${transactionSefName}`
            )}`,
            text: `${categoryName} ${locationName} ${transactionName}`,
        };
    };
