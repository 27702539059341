import { FC } from 'react';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { PromoModule } from 'components/page/advertisement/listing/components/common/promoModule/PromoModule';
import ValueOfRealEstateIcon from 'components/page/advertisement/listing/assets/icons/ValueOfRealEstateIcon.svg';
import { useRouter } from 'next/navigation';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {ETrackEventCategory} from 'modules/gtmEvents/enums/ETrackEventCategory';
import {EGtmEvent} from 'modules/gtmEvents/enums/EGtmEvent';
import {IGtmEvent} from 'modules/analytics/IAnalytics';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';

export const ValueOfRealEstate: FC = () => {
    const router = useRouter();
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const onClick = () => {
        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            {
                action: 'banner',
                event: EGtmEvent.TRACK_EVENT,
                label: 'aka-je-hodnota-vasej-nehnutelnosti',
                category: ETrackEventCategory.ADVERTISEMENT_LISTING,
            },
        ];

        gtmSendEvent(...events);

        router.push(
            `${AppConfigService.getNehnutelnostiUrl()}/ocenovanie-nehnutelnosti/`
        );
    };

    return (
        <PromoModule
            btnText={'Vypočítať teraz'}
            icon={ValueOfRealEstateIcon}
            title={'Aká je hodnota vašej nehnuteľnosti?'}
            description={'Okamžité a bezplatné online ocenenie'}
            onClick={onClick}
        />
    );
};
