/* eslint-disable */
import {FC, ReactNode} from 'react';
import Link from 'next/link';
import {Pagination as MuiPagination, PaginationItem, Box, PaginationRenderItemParams} from '@mui/material';
import {ScrollToTopBtn} from 'modules/theme/components/paginator/partials/scrollToTopBtn/ScrollToTopBtn';
import {ArrowButtons} from 'modules/theme/components/paginator/partials/arrowButtons/ArrowButtons';

interface IPaginator {
    nextHref?: string;
    prevHref?: string;
    isDisabled?: boolean;
    currentPage: number;
    onClick?: () => void;
    totalPageCount: number;
    showScrollToTopBtn?: boolean;
    itemHref?: (page: number) => string;
    onChange?: (newPage: number) => void;
}

export const Paginator: FC<IPaginator> = ({
    nextHref,
    prevHref,
    currentPage,
    totalPageCount,
    onChange,
    onClick,
    itemHref,
    isDisabled,
}) => {
    const isLinksPagination = typeof itemHref !== 'undefined';

    const renderPaginationItem = (item: PaginationRenderItemParams): ReactNode => {
        if (!isLinksPagination || !item.page) {
            return <PaginationItem {...item}/>;
        }

        const onClickHandler = typeof onClick !== 'undefined' &&
            currentPage !== item.page ? onClick : undefined;

        return <Link
            shallow
            prefetch={false}
            onClick={onClickHandler}
            href={itemHref(item.page)}
            scroll={currentPage !== item.page}
        >
            <PaginationItem {...item} />
        </Link>;
    };

    return <Box
        display="flex"
        width={'100%'}
        alignItems="center"
        position="relative"
        justifyContent="center"
        flexDirection={{xs: 'column', md: 'row'}}
        data-test-id="paginatorWithArrowButtons"
    >
        <Box position="absolute" left={0} display={{xs: 'none', md: 'block'}}>
            <ScrollToTopBtn/>
        </Box>

        <ArrowButtons
            onClick={onClick}
            onChange={onChange}
            nextHref={nextHref}
            prevHref={prevHref}
            isDisabled={isDisabled}
            currentPage={currentPage}
            totalPageCount={totalPageCount}
        />

        <MuiPagination
            hidePrevButton
            hideNextButton
            page={currentPage}
            disabled={isDisabled}
            count={totalPageCount}
            data-test-id="paginator"
            sx={{
                right: 0,
                display: 'inline-block',
                position: {xs: 'inherit', md: 'absolute'},
            }}
            renderItem={item => renderPaginationItem(item)}
            onChange={(e, page) => (currentPage !== page && !isLinksPagination) && onChange(page)}
        />
    </Box>
}
