import { FC, FunctionComponent, SVGProps } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Title } from 'components/page/advertisement/listing/components/common/promoModule/common/title/Title';
import { CtaBtn } from 'components/page/advertisement/listing/components/common/promoModule/common/ctaBtn/CtaBtn';
import { Description } from 'components/page/advertisement/listing/components/common/promoModule/common/description/Description';

interface IPromoBox {
    title: string;
    btnText: string;
    description: string;
    promoIcon: FunctionComponent<SVGProps<SVGSVGElement>>;
    onClick: () => void;
}

export const PromoBox: FC<IPromoBox> = ({
    title,
    btnText,
    promoIcon,
    description,
    onClick,
}) => {
    return (
        <Stack
            alignItems={'flex-start'}
            border={'1px solid'}
            rowGap={2}
            borderRadius={(theme: Theme) => theme.spacing(2.5)}
            padding={(theme) => theme.spacing(3)}
            borderColor={'rgba(182, 182, 182, 0.36)'}
        >
            <Stack rowGap={1}>
                <Title text={title} />
                <Description description={description} />
            </Stack>
            <Box
                display={'flex'}
                alignItems={'flex-end'}
                justifyContent={'space-between'}
                width={'100%'}
            >
                <CtaBtn btnText={btnText} onClick={onClick} />
                <Box
                    display={'flex'}
                    alignItems={'flex-end'}
                    position={'absolute'}
                    right={(theme: Theme) => theme.spacing(3)}
                >
                    <Svg icon component={promoIcon} width={88} height={56} />
                </Box>
            </Box>
        </Stack>
    );
};
