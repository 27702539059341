import {ReactNode, useCallback, useEffect, useState} from 'react';
import useLoading from 'modules/state/app/hook/useLoading';
import {FavoriteActionType} from 'components/favorite/types/FavoriteActionType';
import {deleteFromFavorite} from 'components/favorite/api/deleteFromFavorite';
import {EFavoriteActions} from 'components/favorite/enums/EFavoriteActions';
import {addToFavorite} from 'components/favorite/api/addToFavorite';
import {useLoginModal} from 'components/loginModal/hooks/useLoginModal';
import useNotification from 'modules/notification/useNotification';
import {getIsFavorite} from 'components/favorite/api/getIsFavorite';
import {useLocalStorageItem} from 'modules/localStorage/hooks/useLocalStorageitem';
import {useRecoilState} from 'recoil';
import {ELoginPosition} from 'modules/gtmEvents/enums/login/ELoginPosition';
import {loginPosition} from 'modules/state/gaAnalytics/login/state';

interface IReturn {
    isFavorite: boolean;
    isLoading: boolean;
    loginModal: ReactNode;
    onAddOrDeleteFromFavorite: (action: FavoriteActionType) => void;
}

export const useFavorite = (
    advertisementId: string,
    isFavoriteInitial?: boolean
): IReturn => {
    const [isFavorite, setIsFavorite] = useState<boolean>(isFavoriteInitial);
    const { start: startLoading, stop: stopLoading, isLoading } = useLoading();
    const { loginModal, isLoggedIn, showLoginDialog } = useLoginModal();
    const { success: showSuccessNotification, info: showInfoNotification } = useNotification();
    const [, setLoginPosition,] = useRecoilState<ELoginPosition>(loginPosition);

    useEffect(() => {
        setIsFavorite(isFavoriteInitial);
    }, [isFavoriteInitial]);

    const {
        get: getStorageAdvId,
        set: setStorageAdvId,
        remove: removeStorageAdvId,
    } = useLocalStorageItem('add-to-favorites');

    const onAddToFavoriteFromAnonymousUser = useCallback(
        async (storageAdvId: string): Promise<void> => {
            if (!isLoggedIn || isFavoriteInitial !== undefined || storageAdvId === null) {
                return;
            }

            try {
                startLoading();

                await addToFavorite(storageAdvId);
                // set FE favorite for main photo only
                if(storageAdvId === advertisementId){
                    setIsFavorite(true);
                }
                showSuccessNotification('Inzerát pridaný medzi obľúbené');

                stopLoading();
            } catch (error) {
                console.warn(error.message);
            } finally {
                stopLoading();
            }
        },
        [
            advertisementId,
            isLoggedIn,
            isFavoriteInitial,
            startLoading,
            stopLoading,
            showSuccessNotification,
        ]
    );

    const onAddOrDeleteFromFavorite = useCallback(
        async (action: FavoriteActionType): Promise<void> => {
            if (!isLoggedIn) {
                setStorageAdvId(advertisementId);
                setLoginPosition(ELoginPosition.FAVORITE);
                return showLoginDialog();
            }

            const apiAction =
                action === EFavoriteActions.ADD_TO_FAVORITE
                    ? addToFavorite
                    : deleteFromFavorite;

            try {
                startLoading();

                await apiAction(advertisementId);

                setIsFavorite(action === EFavoriteActions.ADD_TO_FAVORITE);

                if (action === EFavoriteActions.ADD_TO_FAVORITE) {
                    showSuccessNotification('Inzerát pridaný medzi obľúbené');
                } else {
                    showInfoNotification('Inzerát odobraný z obľúbených');
                }

                stopLoading();
            } catch (error) {
                console.warn(error.message);
            } finally {
                stopLoading();
            }
        },
        [isLoggedIn, setStorageAdvId, advertisementId, setLoginPosition, showLoginDialog, startLoading, stopLoading, showSuccessNotification, showInfoNotification]
    );

    const fetchIsFavorite = useCallback(async (): Promise<void> => {
        if (!isLoggedIn || isFavoriteInitial !== undefined) {
            return;
        }

        try {
            startLoading();

            if(getStorageAdvId() !== null){
                await onAddToFavoriteFromAnonymousUser(getStorageAdvId());
            }
            const { data } = await getIsFavorite(advertisementId);

            setIsFavorite(data);

            stopLoading();
            removeStorageAdvId();
        } catch (error) {
            console.warn(error.message);
        } finally {
            stopLoading();
        }
    }, [
        isLoggedIn,
        isFavoriteInitial,
        startLoading,
        advertisementId,
        stopLoading,
        onAddToFavoriteFromAnonymousUser,
        getStorageAdvId,
        removeStorageAdvId,
    ]);

    useEffect(() => {
        fetchIsFavorite();
    }, [fetchIsFavorite]);

    return {
        isFavorite,
        isLoading,
        loginModal,
        onAddOrDeleteFromFavorite,
    };
};
