import { FC } from 'react';
import { Button } from 'modules/theme/components/button/Button';

interface IShowMoreBtn {
    onClick: () => void;
    text: string;
}

export const ShowMoreBtn: FC<IShowMoreBtn> = ({ onClick, text }) => {
    return <Button onClick={onClick} text={text} textBtn />;
};
