import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { AdSlot } from 'components/ads/enums/AdSlot';
import { Ad } from 'components/ads/components/ad/Ad';
import { AdvertBox } from 'components/page/advertisement/listing/components/listing/components/advertBox/AdvertBox';
import { ValueOfRealEstate } from 'components/page/advertisement/listing/components/listing/partials/items/partials/promoServicesBox/partials/valueOfRealEstate/ValueOfRealEstate';


interface IPromoServicesBox {
    index: number;
}

export const PromoServicesBox: FC<IPromoServicesBox> = ({index}) => {
    const SCREEN_TOP_BANNER_INDEX_AND_EXCLUSIVE_PROJECTS_INDEX = 0;
    const BANNER_LIST_01_INDEX = 4;
    const BANNER_LIST_02_INDEX = 9;
    const BANNER_LIST_03_INDEX = 15;
    const VALUE_OF_REAL_ESTATE_INDEX = 24;
    const BANNER_LIST_04_INDEX = 26;

    const renderAdditionalBox = (): ReactNode | null => {
        switch (index + 1) {
        case SCREEN_TOP_BANNER_INDEX_AND_EXCLUSIVE_PROJECTS_INDEX:
            return (
                <AdvertBox key={index}>
                    <Ad slotDesktop={AdSlot.AD_DESKTOP_SCREEN_TOP} slotMobile={AdSlot.AD_MOBILE_TOP}/>
                </AdvertBox>
            );
        case BANNER_LIST_01_INDEX:
            return (
                <AdvertBox key={index}>
                    <Ad slotDesktop={AdSlot.AD_DESKTOP_LIST_1} slotMobile={AdSlot.AD_MOBILE_LIST_1}/>
                </AdvertBox>
            );
        case BANNER_LIST_02_INDEX:
            return (
                <AdvertBox key={index}>
                    <Ad slotDesktop={AdSlot.AD_DESKTOP_LIST_2} slotMobile={AdSlot.AD_MOBILE_LIST_2}/>
                </AdvertBox>
            );
        case BANNER_LIST_03_INDEX:
            return (
                <AdvertBox key={index}>
                    <Ad slotDesktop={AdSlot.AD_DESKTOP_LIST_3} slotMobile={AdSlot.AD_MOBILE_LIST_3}/>
                </AdvertBox>
            );
        case BANNER_LIST_04_INDEX:
            return (
                <AdvertBox key={index}>
                    <Ad slotDesktop={AdSlot.AD_DESKTOP_LIST_4} slotMobile={AdSlot.AD_MOBILE_LIST_4}/>
                </AdvertBox>
            );
        case VALUE_OF_REAL_ESTATE_INDEX:
            return (
                <Box key={index} mb={4}>
                    <ValueOfRealEstate />
                </Box>
            );
        default:
            null;
        }
    };

    return <>{renderAdditionalBox()}</>;
};