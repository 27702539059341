import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Palette } from '@mui/material';

interface IProps {
    title: string;
}

export const BreadcrumbWithoutUrl: FC<IProps> = ({ title }) => {
    return (
        <Text
            variant={'breadcrumb'}
            color={(palette: Palette) => palette.labelSecondary}
        >
            {title}
        </Text>
    );
};
