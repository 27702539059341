import { FC } from 'react';
import { ApiCategoriesType } from 'components/advertisementsFilter/modules/common/types/ApiCategoriesType';
import { DevProject } from 'components/page/advertisement/listing/components/listing/partials/items/partials/item/devProject/DevProject';
import { Advertisement } from 'components/page/advertisement/listing/components/listing/partials/items/partials/item/advertisement/Advertisement';
import { IAdvertisementsListResult } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/IAdvertisementsListResult';
import { IAdvertisementGtmListingItem } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';

interface IFirstAdvertisement {
    page: number;
    limit: number;
    item: IAdvertisementsListResult;
    gtmItem: IAdvertisementGtmListingItem;
    categoriesFromSearchFilter: ApiCategoriesType;
}

export const FirstAdvertisement: FC<IFirstAdvertisement> = ({
    item,
    page,
    limit,
    gtmItem,
    categoriesFromSearchFilter,
}) => {
    return <>
        {!!item.advertisement && (
            <Advertisement
                index={-1}
                page={page}
                limit={limit}
                isFirstAdvertisement
                advertisement={item.advertisement}
                advertisementGtmItem={gtmItem}
                filterCategoriesForDevProject={
                    categoriesFromSearchFilter
                }
            />
        )}

        {!!item.devProject && (
            <DevProject
                index={-1}
                page={page}
                limit={limit}
                devProject={item.devProject}
                advertisementGtmItem={gtmItem}
            />
        )}
    </>;
};
