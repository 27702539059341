import { FC } from 'react';
import { BreadcrumbsBox as MobileBreadcrumbsBox } from 'components/page/advertisement/listing/components/anotherOffer/partials/breadcrumbs/partials/mobile/BreadcrumbsBox';
import { BreadcrumbsBox as DesktopBreadcrumbsBox } from 'components/page/advertisement/listing/components/anotherOffer/partials/breadcrumbs/partials/desktop/BreadcrumbsBox';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
interface IBreadcrumbs {
    breadcrumbs: IBreadcrumb[];
}

export const Breadcrumbs: FC<IBreadcrumbs> = ({ breadcrumbs }) => {
    return <>
        <Mobile>
            <MobileBreadcrumbsBox breadcrumbs={breadcrumbs} />
        </Mobile>
        <Desktop>
            <DesktopBreadcrumbsBox breadcrumbs={breadcrumbs} />
        </Desktop>
    </>;
};
