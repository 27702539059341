import { create } from 'zustand';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import {
    IAdvertisementsListResult
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/IAdvertisementsListResult';

type AdvertisementsListingStore = {
    favoriteIds: string[];
    breadcrumbs: IBreadcrumb[];
    advertisementIds: string[];
    setFavoriteIds: (ids: string[]) => void;
    setAdvertisementsIds: (data: IAdvertisementsListResult[]) => void;
};

export const useAdvertisementsListingStore = create<AdvertisementsListingStore>((set) => ({
    breadcrumbs: [],
    favoriteIds: [],
    advertisements: [],
    advertisementIds: [],

    setAdvertisementsIds: (data) => set(() => {
        return {
            advertisementIds: data.map((item) => {
                if (item.advertisement) return item.advertisement.id;
            }),
        };
    }),

    setFavoriteIds: (ids) => set(() => {
        return { favoriteIds: ids };
    }),
}));