import { IAdvertisementsListResultAdvertisementFlags } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/advertisement/partials/flags/IAdvertisementsListResultAdvertisementFlags';
import { IPackages } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/packages/IPackages';

export const mapIAdvertisementsListResultAdvertisementFlagsToDsIAdvertisementIPackages =
    (apiFlags: IAdvertisementsListResultAdvertisementFlags): IPackages => {
        const { isTop, isPremium } = apiFlags;

        return {
            isTop,
            isPremium,
        };
    };
