import {FC} from 'react';
import Link from 'next/link';
import { Button } from 'modules/theme/components/paginator/partials/arrowButtons/Button';

interface IArrowButton {
    href: string;
    testId?: string;
    currentPage: number;
    onClick?: () => void;
    isDisabled?: boolean;
    direction: 'back' | 'forward';
    onChange?: (newPage: number) => void;
}

export const ArrowButton: FC<IArrowButton> = ({
    href,
    currentPage,
    onClick,
    onChange,
    direction,
    isDisabled,
    testId
}) => {
    const isDirectionForward = direction === 'forward';
    const nextPage = isDirectionForward ? currentPage + 1 : currentPage - 1;

    const button = <Button
        testId={testId}
        onChange={!href && onChange}
        nextPage={nextPage}
        isDisabled={isDisabled}
        isDirectionForward={isDirectionForward}
    />;

    const component = href
        ? <Link
            scroll
            shallow
            href={href}
            prefetch={false}
            onClick={onClick}
        >
            {button}
        </Link>
        : button;

    return <>{component}</>;
};
