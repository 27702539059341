import { AxiosResponse } from 'axios';
import { advertisementFavoriteApi } from 'modules/api/client';
import { FavouriteAdvertisementsInFavouriteDto } from 'modules/api/generated';

export const getMultipleIsFavourite = async (
    advertisementIds: string[]
): Promise<AxiosResponse<string[]>> => {
    const favouriteAdvertisementsInFavouriteDto: FavouriteAdvertisementsInFavouriteDto =
        {
            ids: advertisementIds,
        };

    try {
        const response = await advertisementFavoriteApi.areInFavourite(
            favouriteAdvertisementsInFavouriteDto
        );

        if (response.status !== 201) {
            throw new Error(`Response status code was: ${response.status}`);
        }

        return response;
    } catch (error) {
        `Failed to multiple favourite check . Error: ${error.message}`;
    }
};
