import { FC } from 'react';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { TabBar } from 'modules/theme/components/tabbar/TabBar';
import { ITabBarItem } from 'modules/theme/components/tabbar/interfaces/ITabBarItem';

import ListingIcon from 'modules/theme/components/icons/tabBar/Listing.svg';
import ServicesIcon from 'modules/theme/components/icons/tabBar/Services.svg';
import AgentIcon from 'modules/theme/components/icons/tabBar/Agent.svg';
import AccountIcon from 'modules/theme/components/icons/tabBar/Account.svg';

export const MobileTabBar: FC = () => {
    const items: ITabBarItem[] = [
        {
            id: '1',
            icon: ListingIcon,
            label: 'Inzercia',
            href: '',
            isActive: true,
        },
        {
            id: '2',
            icon: ServicesIcon,
            label: 'Služby',
            href: `${AppConfigService.getNehnutelnostiUrl()}/sluzby`,
        },
        {
            id: '3',
            icon: AgentIcon,
            label: 'Makléri',
            href: `${AppConfigService.getNehnutelnostiUrl()}/realitne-kancelarie-makleri`,
        },
        {
            id: '4',
            icon: AccountIcon,
            label: 'Konto',
            href: `${AppConfigService.getNehnutelnostiUrl()}/moje-konto`,
        },
    ];

    return <TabBar items={items} />;
};
